.App {
  text-align: center;

  .inputs {
    padding: 10px;
    max-width: 500px;
    margin: auto;
  }

  .savedLoan {
    background-color: aquamarine;
    position: relative;
    min-width: 180px;
    margin: 5px;
    cursor: pointer;
    .closeX {
      position: absolute;
      top: 0;
      right: 7px;
      font-size: 1.25rem;
    }
  }
}

.Tae {
  .inputs input {
    text-align: right;
  }

  .tableFixHead {
    overflow-y: auto;
    height: 300px;
    margin-bottom: 10px;

    thead th {
      position: sticky;
      top: -1px;
    }
  }
}